.logo {
    height: 32px;
    margin: 16px;
    /* background: rgba(255, 255, 255, 0.3); */
  }

  .nav-item {
    color: azure;
    height:max-content;
    cursor: pointer;
    padding:0 20px 0 20px;
    transition: all 0.6s;
  }
  
  .nav-item:hover{
      background: rgba(255,255,255,0.1);
  }